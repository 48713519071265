import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip'

import InfoIcon from '../../asset/images/info.svg'
import SliderStyled from './styledComponent'
import colors from '../../colors'

const StyledFormControl = withStyles({
  root: {
    marginRight: 0
  }
})(FormControlLabel)

const StyleSlider = withStyles({
  root: {
    width: 198,
    left: 20
  },
  thumb: {
    width: 20,
    height: 20,
    zIndex: 0,
    backgroundColor: '#fff',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.3)'
  },
  track: {
    backgroundColor: colors.jadeGreen,
    height: 8,
    borderRadius: 4
  },
  rail: {
    backgroundColor: colors.greyDisabled,
    height: 8,
    borderRadius: 4
  }
})(Slider)

const SliderComponent = (props) => {
  const iconEl = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', (e) => handleClickOutside(e))

    return document.removeEventListener('mousedown', (e) => handleClickOutside(e))
  }, [])

  const handleClickOutside = (e) => {
    if (iconEl.current && !iconEl.current.contains(e.target)) {
      setToggleTooltip(false)
    }
  }

  const renderIconTooltip = () => {
    if (props.tooltipInfo) {
      return (
        <div className="icon-wrapper" onClick={() => setToggleTooltip(!showTooltip)} ref={iconEl}>
          <img src={InfoIcon} alt="" />
        </div>
      )
    }
  }

  const renderTitleSlider = () => {
    const { label, value, max, valueType } = props
    const finalValue = valueType === 'number' ? value : `${Math.round((value / max) * 100)}%`
    return (
      <div className="flex space-between align-items-center title-wrapper">
        <Tooltip title={label}>
          <div className="slider-label prevent-text-overflow">{label}</div>
        </Tooltip>
        <div className="text-style">{finalValue}</div>
        {renderIconTooltip()}
      </div>
    )
  }

  const [showTooltip, setToggleTooltip] = useState(false)

  const tooltipInfo = () => {
    const { tooltipInfo } = props
    let output = null
    if (showTooltip) {
      output = <div className="tooltip-wrapper text-style">{tooltipInfo}</div>
    }
    return output
  }

  const renderFooterSlider = () => {
    return (
      <div className="slider-footer-wrapper flex space-between text-style">
        <div className="left-footer">{props.leftFooterLabel}</div>
        <div className="right-footer">{props.rightFooterLabel}</div>
      </div>
    )
  }

  return (
    <SliderStyled className={props.className}>
      {renderTitleSlider()}
      <StyledFormControl
        control={
          <StyleSlider
            value={props.value}
            min={props.min}
            max={props.max}
            step={props.step}
            valueLabelDisplay={props.valueLabelDisplay}
            onChange={(e, value) => props.onChange(e, value)}
            onChangeCommitted={(e, value) => props.onChangeCommitted(e, value)}
            disabled={props.disabled}
          />
        }
      />
      {renderFooterSlider()}
      {tooltipInfo()}
    </SliderStyled>
  )
}

SliderComponent.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  valueLabelDisplay: PropTypes.oneOf(['on', 'off']),
  onChange: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  leftFooterLabel: PropTypes.string,
  rightFooterLabel: PropTypes.string,
  disabled: PropTypes.bool,
  valueType: PropTypes.oneOf(['number', 'percent']),
  tooltipInfo: PropTypes.string
}

export default SliderComponent
