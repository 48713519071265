import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 14px;
  position: relative;
  .MuiSlider-thumb {
    &.Mui-disabled {
      width: 20px;
      height: 20px;
      margin-top: -5px;
      margin-left: -6px;
      background: ${(props) => props.theme.greyDisabled};
      &:hover {
        cursor: not-allowed;
      }
    }
  }
  .title-wrapper {
    position: relative;
    .slider-label {
      font-family: 'Prompt-Medium', sans-serif;
      font-size: 12px;
      width: 170px;
      padding-left: 10px;
    }
    .text-style {
      font-size: 12px;
    }
    .icon-wrapper {
      width: 16px;
      height: 16px;
      position: absolute;
      right: -24px;
      &:hover {
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .slider-footer-wrapper {
    font-family: 'Prompt', sans-serif;
    font-size: 12px;
    color: #969696;
    padding-left: 10px;
  }

  .tooltip-wrapper {
    width: 200px;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    background: white;
    font-family: 'Prompt', sans-serif;
    font-size: 12px;
    position: absolute;
    right: -250px;
    z-index: 1;
  }
`
